import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'scraper',
	id: 'scraper',
	loader: () => ({
		meta: {
			title: 'Scraper',
			to: { id: 'scraperHome' },
			htmlTitlePattern: '#title :: Scraper ',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/scraper/Layout')).default }),
	children: [
		{
			id: 'scraperHome',
			index: true,
			path: '',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/Home')).default }),
			loader: () => ({
				meta: {
					title: 'Home',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperArkansas',
			index: true,
			path: 'arkansas',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/Arkansas.tsx')).default }),
			loader: () => ({
				meta: {
					title: 'Arkansas',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperFlorida',
			index: true,
			path: 'florida',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/Florida')).default }),
			loader: () => ({
				meta: {
					title: 'Florida',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperGeorgia',
			index: true,
			path: 'georgia',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/Georgia')).default }),
			loader: () => ({
				meta: {
					title: 'Georgia',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperIllinois',
			index: true,
			path: 'illinois',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/Illinois')).default }),
			loader: () => ({
				meta: {
					title: 'Illinois',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperIllinoisReport',
			index: true,
			path: 'illinois/report',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/IllinoisReport')).default }),
			loader: () => ({
				meta: {
					title: 'Illinois Report',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperMassachusetts',
			index: true,
			path: 'massachusetts',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/Massachusetts')).default }),
			loader: () => ({
				meta: {
					title: 'Massachusetts',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperSouthCarolina',
			index: true,
			path: 'southCarolina',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/SouthCarolina')).default }),
			loader: () => ({
				meta: {
					title: 'South Carolina',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperVirginia',
			index: true,
			path: 'virginia',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/Virginia')).default }),
			loader: () => ({
				meta: {
					title: 'Virginia',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperLouisiana',
			index: true,
			path: 'louisiana',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/Louisiana')).default }),
			loader: () => ({
				meta: {
					title: 'Louisiana',
					disabled: true,
				} as RouteMeta,
			}),
		},
	],
};

export default route;
