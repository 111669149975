import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes.tsx';
import settings from '@/modules/essentialDocuments/settings';
import reports from '@/modules/essentialDocuments/reports';

const route: RouteObject = {
	path: 'essentialDocuments',
	loader: () => ({
		meta: {
			title: 'Essential Documents',
			to: { id: 'essentialDocumentsHome' },
			disabled: true,
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/essentialDocuments/Layout.tsx')).default }),
	children: [
		{
			path: '',
			index: true,
			id: 'essentialDocumentsHome',
			lazy: async () => ({ Component: (await import('@/modules/essentialDocuments/pages/Home.tsx')).default }),
		},
		{
			path: 'boi',
			index: true,
			id: 'boi',
			loader: () => ({
				meta: {
					title: 'BOI',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/essentialDocuments/pages/BOI.tsx')).default }),
		},
		{
			path: 'certificate',
			index: true,
			id: 'certificate',
			loader: () => ({
				meta: {
					title: 'Certificate',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/essentialDocuments/pages/Certificate.tsx')).default }),
		},
		{
			path: 'ein',
			index: true,
			id: 'ein',
			loader: () => ({
				meta: {
					title: 'EIN',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/essentialDocuments/pages/EIN.tsx')).default }),
		},
		settings,
		reports,
	],
};
export default route;
