import { create } from 'zustand';
import { removeArrayElement } from '@/lib/utility';
import { sharedEntities, TemplateUserValue } from 'shared/types';
import { SiteGroup } from 'shared/enums';
import { api } from '@/lib/connectivity';
import { ReactNode } from 'react';
import { uniqBy } from 'lodash';
import { devtools } from 'zustand/middleware';
import { ComboboxData } from '@/lib/components/AppCombo.tsx';
import {
	annualReportTemplateUserOptions,
	boiTemplateUserOptions,
	certificateTemplateUserOptions,
	einTemplateUserOptions,
	fictitiousTemplateUserOptions,
	invoiceTemplateUserOptions,
	laborLawTemplateUserOptions,
	propertyDocumentTemplateUserOptions,
} from 'shared/consts.ts';

export type FetchedBankEntity = Pick<sharedEntities.BankEntity, '_id' | 'accountNo' | 'company' | 'name' | 'dba' | 'currentCheckNo'>;
export type FetchedWebsiteEntity = Pick<sharedEntities.WebsiteEntity, 'short' | 'name' | 'group' | '_id' | 'web' | 'irsFaxTo'>;
export type GroupType =
	| SiteGroup.AnnualReport
	| SiteGroup.Certificate
	| SiteGroup.EIN
	| SiteGroup.Fictitious
	| SiteGroup.LaborLaw
	| SiteGroup.PropertyDocument
	| SiteGroup.BOI
	| SiteGroup.EssentialDocument;

type EssentialStore = Record<GroupType, Array<Pick<sharedEntities.CollectionEntity, '_id' | 'columns' | 'name' | 'abbreviation'>>> & {
	YourKits: Array<sharedEntities.YourKitsEntity>;
	settings: sharedEntities.SettingsEntity;
	banks: Array<FetchedBankEntity>;
	websites: Array<FetchedWebsiteEntity>;
	inProgress: string[];
	expenseCategories: Array<sharedEntities.ExpenseCategoryEntity>;
	currentUser?: sharedEntities.UserEntity;
	setUser(payload: any): void;
	addInProgress(payload: string): void;
	removeInProgress(payload: string): void;
	setEssential(payload: any): void;
	setSettings(payload: any): void;
	setBanks(payload: any): void;
	fetchBanks(): Promise<void>;
	fetchExpenseCategories(): Promise<void>;
	fetchWebsites(group: SiteGroup): Promise<void>;
	isAuthenticated(): boolean;
	currentUserID(): string | undefined;
	merchantProcessing(): sharedEntities.MerchantProcessingEntity;
	websitesByGroup(group: SiteGroup): Array<FetchedWebsiteEntity>;
	websiteByID(id: string): FetchedWebsiteEntity | undefined | null;
	getYourKitsProfile(id: string): sharedEntities.YourKitsEntity | null;
	getAnnualReportCollection(abbreviation: string): sharedEntities.CollectionEntity | null;
	getLaborLawCollection(abbreviation: string): sharedEntities.CollectionEntity | null;
	getFictitiousCollection(abbreviation: string): sharedEntities.CollectionEntity | null;
	getPropertyDocumentCollection(abbreviation: string): sharedEntities.CollectionEntity | null;
	getCollection(group?: SiteGroup, abbreviation?: string): sharedEntities.CollectionEntity | null;
	getBank(id: string): FetchedBankEntity | null | undefined;
	getBankName(id: string, withCompany?: boolean, companyInNewLine?: boolean, inline?: boolean): ReactNode;

	getGroupTemplateUserOptions: (group: SiteGroup) => Array<TemplateUserValue>;

	emailTemplates: Array<sharedEntities.EmailTemplateEntity>;
	fetchEmailTemplates: (group: SiteGroup, siteID?: string) => Promise<void>;
	getEmailTemplatesOfSite: (siteID?: string) => Array<ComboboxData<sharedEntities.EmailTemplateEntity>>;
	getEmailTemplatesOfGroup: (group: SiteGroup) => Array<ComboboxData<sharedEntities.EmailTemplateEntity>>;
};
export const useEssentialStore = create<EssentialStore>()(
	devtools(
		(setState, getState) =>
			({
				inProgress: [],
				[SiteGroup.LaborLaw]: [],
				[SiteGroup.AnnualReport]: [],
				[SiteGroup.Certificate]: [],
				[SiteGroup.EIN]: [],
				[SiteGroup.Fictitious]: [],
				[SiteGroup.PropertyDocument]: [],
				[SiteGroup.BOI]: [],
				[SiteGroup.YourKits]: [],
				[SiteGroup.EssentialDocument]: [],
				settings: undefined,
				websites: [],
				banks: [],
				currentUser: null,
				expenseCategories: [],
				emailTemplates: [],
				setUser(payload: any) {
					setState({ currentUser: payload });
				},
				addInProgress(payload: string) {
					if (!getState().inProgress.includes(payload)) setState((state) => ({ inProgress: [...state.inProgress, payload] }));
				},
				removeInProgress(payload: string) {
					setState({ inProgress: removeArrayElement(getState().inProgress, payload) });
				},
				setEssential(payload: any) {
					setState({
						[SiteGroup.LaborLaw]: payload.laborLaw,
						[SiteGroup.AnnualReport]: payload.annualReport,
						[SiteGroup.PropertyDocument]: payload.propertyDocument,
						[SiteGroup.Fictitious]: payload.fictitious,
						settings: payload.settings,
						websites: payload.websites,
						[SiteGroup.YourKits]: payload.yourKits,
					});
				},
				setSettings(payload: any) {
					setState({ settings: payload });
				},
				setBanks(payload: any) {
					setState({ banks: payload });
				},
				fetchBanks() {
					return new Promise<void>((resolve) => {
						api
							.get('bank', {
								params: {
									fields: '_id name accountNo company.name dba currentCheckNo',
								},
							})
							.then(({ data }) => {
								setState({ banks: data.body });
							})
							.finally(resolve);
					});
				},
				fetchExpenseCategories() {
					return new Promise<void>((resolve) => {
						api
							.get<Array<sharedEntities.ExpenseCategoryEntity>>('expense/categories')
							.then(({ data }) => {
								if (data.isSuccess) setState({ expenseCategories: data.body });
							})
							.finally(resolve);
					});
				},
				fetchWebsites(group: SiteGroup) {
					return api.get(`settings/profile/website/${group}`).then(({ data }) => {
						if (data.isSuccess) {
							setState((state) => ({ websites: uniqBy([...data.body, ...state.websites], '_id') }));
						}
					});
				},
				//getters
				isAuthenticated() {
					return !!getState()?.currentUser?._id;
				},
				currentUserID() {
					return getState()?.currentUser?._id;
				},
				merchantProcessing() {
					return getState()?.settings?.merchantProcessing;
				},
				websitesByGroup: (group: SiteGroup) => getState().websites.filter((w) => w.group === group),
				websiteByID: (id: string) => (id ? getState().websites.find((w) => w._id === id) : null),
				getYourKitsProfile: (id: string | null) => getState().YourKits.find((w) => w._id === id),
				getBank: (id: string) => getState().banks.find((w) => w._id === id),
				getBankName: (id: string, withCompany = false, companyInNewLine = true, inline = false) => {
					// @ts-ignore
					const bank = getState()?.getBank(id);
					if (bank)
						return (
							<>
								{withCompany && <i> {bank.company?.name}</i>}
								{companyInNewLine ? <br /> : ' '}
								<strong>{bank.name}</strong>
								{!inline && <br />}
								{inline && '  ('}AC: {bank.accountNo}
								{inline && ')'}
							</>
						);
				},
				getAnnualReportCollection: (abbreviation: string | null) => getState().AnnualReport.find((w) => w.abbreviation === abbreviation),
				getLaborLawCollection: (abbreviation: string | null) => getState().LaborLaw.find((w) => w.abbreviation === abbreviation),
				getFictitiousCollection: (abbreviation: string | null) => getState().Fictitious.find((w) => w.abbreviation === abbreviation),
				getPropertyDocumentCollection: (abbreviation: string | null) => getState().PropertyDocument.find((w) => w.abbreviation === abbreviation),

				getCollection: (group?: SiteGroup, abbreviation?: string | null): sharedEntities.CollectionEntity | null =>
					group ? getState()[group].find((w) => w.abbreviation === abbreviation) : null,

				fetchEmailTemplates: async (group, siteID) => {
					return api
						.get<sharedEntities.ProductEntity[]>(`settings/template/email/${group}`, {
							params: {
								siteID,
							},
						})
						.then(({ data }) => {
							setState({ emailTemplates: uniqBy([...data.body, ...getState().emailTemplates], '_id') });
						});
				},
				getEmailTemplatesOfSite: (siteID: string) => {
					return getState()
						.emailTemplates.filter((template) => template.sites.includes(siteID))
						.map((template) => ({
							value: template,
							label: template.identifier,
							description: template.subject,
							_id: template._id,
						}));
				},
				getEmailTemplatesOfGroup: (group) => {
					return getState()
						.emailTemplates.filter((template) => template.group === group)
						.map((template) => ({
							value: template,
							label: template.identifier,
							description: template.subject,
							_id: template._id,
						}));
				},

				getGroupTemplateUserOptions: (group: SiteGroup) => {
					return group == SiteGroup.LaborLaw
						? laborLawTemplateUserOptions
						: group == SiteGroup.AnnualReport
							? annualReportTemplateUserOptions
							: group == SiteGroup.Fictitious
								? fictitiousTemplateUserOptions
								: group == SiteGroup.PropertyDocument
									? propertyDocumentTemplateUserOptions
									: group == SiteGroup.EIN
										? einTemplateUserOptions
										: group == SiteGroup.BOI
											? boiTemplateUserOptions
											: group == SiteGroup.Certificate
												? certificateTemplateUserOptions
												: invoiceTemplateUserOptions;
				},

				//add new line above
			}) as EssentialStore,
		{
			name: 'EssentialStore',
			store: 'EssentialStore',
		},
	),
);
