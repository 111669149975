import { ReactNode, useEffect, useState } from 'react';
import { Link, RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { BubbleMenu, Editor, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import {
	ActionIcon,
	Button,
	ColorPicker,
	Group,
	Input,
	InputWrapperProps,
	Menu,
	Modal,
	Popover,
	ScrollArea,
	Select,
	Stack,
	Textarea,
	TextInput,
	Title,
} from '@mantine/core';
import { Superscript } from '@tiptap/extension-superscript';
import { Subscript } from '@tiptap/extension-subscript';
import { Highlight } from '@tiptap/extension-highlight';
import { TextAlign } from '@tiptap/extension-text-align';
import { Underline } from '@tiptap/extension-underline';
import { Image as TipTapImage } from '@tiptap/extension-image';
import { IconCode, IconRectangle, IconUserPin, IconX } from '@tabler/icons-react';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TemplateUserValue } from 'shared/types.ts';
import { ExtensionButtonComponent } from '@/lib/components/TextEditor/ExtensionButtonComponent.tsx';
import { FieldTypes } from 'shared/enums.ts';
import { useDisclosure } from '@mantine/hooks';
import { useEssentialStore } from '@/pages/essential.store.tsx';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';

type TextEditorProps = {
	value?: string;
	onChange?: (html: string | undefined, editor: Editor | null) => void;
	onEditorCreated?: (editor: Editor) => void;
	minimal?: boolean;
	compact?: boolean;
	hideAlignment?: boolean;
	hideSubSup?: boolean;
	returnText?: boolean;
	items: Array<TemplateUserValue>;
	wrapperProps?: Omit<InputWrapperProps, 'children'> & { placeholder?: string };
	mih?: number;
	mah?: number;
	toolbarRight?: ReactNode;
};

const TextEditor = ({
	minimal,
	items,
	wrapperProps,
	value,
	onChange,
	returnText,
	onEditorCreated,
	compact,
	mih,
	mah,
	toolbarRight,
	hideAlignment,
	hideSubSup,
}: TextEditorProps) => {
	const editor = useEditor({
		extensions: [
			StarterKit,
			Link,
			ExtensionButtonComponent,
			Underline,
			Superscript,
			Subscript,
			Highlight.configure({ multicolor: true }),
			TextAlign.configure({ types: ['heading', 'paragraph'] }),
			Placeholder.configure({ placeholder: wrapperProps?.placeholder || '' }),
			TipTapImage.configure({
				allowBase64: true,
				inline: true,
			}),
			TextStyle,
			Color,
		],
		content: value,
	});
	useEffect(() => {
		if (editor) {
			if (onEditorCreated) onEditorCreated(editor);
			editor.on('update', () => {
				onChange?.(returnText ? editor?.getText() : editor?.getHTML(), editor);
			});
		}
	}, [editor]);
	return (
		<Input.Wrapper {...wrapperProps}>
			<RichTextEditor
				classNames={{
					content: 'tw-rounded-none tw-grid',
				}}
				editor={editor}>
				<TextEditorToolbar
					items={items}
					minimal={minimal}
					compact={compact}
					toolbarRight={toolbarRight}
					hideAlignment={hideAlignment}
					hideSubSup={hideSubSup}
				/>
				{editor && !minimal && (
					<BubbleMenu editor={editor}>
						<RichTextEditor.ControlsGroup>
							<RichTextEditor.Bold />
							<RichTextEditor.Italic />
							<RichTextEditor.Link />
						</RichTextEditor.ControlsGroup>
					</BubbleMenu>
				)}
				<ScrollArea.Autosize mah={mah || 400}>
					<RichTextEditor.Content
						bg={'#E1E9F8'}
						mih={mih || 250}
					/>
				</ScrollArea.Autosize>
			</RichTextEditor>
		</Input.Wrapper>
	);
};

export default TextEditor;

const TextEditorToolbar = ({
	minimal,
	compact,
	hideSubSup,
	hideAlignment,
	items,
	toolbarRight,
}: Pick<TextEditorProps, 'minimal' | 'compact' | 'hideAlignment' | 'hideSubSup' | 'items' | 'toolbarRight'>) => {
	const essentialStore = useEssentialStore();
	const { editor } = useRichTextEditorContext();
	const [opened, { open, close }] = useDisclosure();
	const [popoverOpened, { open: openPopover, close: closePopover }] = useDisclosure();
	const [color, setColor] = useState<string>('#1b2942');

	const [label, setLabel] = useState<string>('Preview Button');
	const [html, setHtml] = useState<string>('');
	const [item, setItem] = useState<TemplateUserValue | null>(null);

	useEffect(() => {
		setHtml(editor?.getHTML() || '');
	}, [editor?.getHTML()]);

	return (
		<RichTextEditor.Toolbar
			sticky
			className={'tw-p-1 tw-border-0'}
			stickyOffset={60}>
			{!minimal && (
				<>
					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Bold />
						<RichTextEditor.Italic />
						<RichTextEditor.Underline />
						<RichTextEditor.Strikethrough />
						<RichTextEditor.ClearFormatting />
						<RichTextEditor.ColorPicker
							colors={[
								'#25262b',
								'#868e96',
								'#fa5252',
								'#e64980',
								'#be4bdb',
								'#7950f2',
								'#4c6ef5',
								'#228be6',
								'#15aabf',
								'#12b886',
								'#40c057',
								'#82c91e',
								'#fab005',
								'#fd7e14',
							]}
						/>
						<RichTextEditor.Highlight />
					</RichTextEditor.ControlsGroup>
					{!compact && (
						<>
							<RichTextEditor.ControlsGroup>
								<RichTextEditor.H1 />
								<RichTextEditor.H2 />
								<RichTextEditor.H3 />
								<RichTextEditor.H4 />
							</RichTextEditor.ControlsGroup>
							<RichTextEditor.ControlsGroup>
								<RichTextEditor.Blockquote />
								<RichTextEditor.Hr />
								<RichTextEditor.BulletList />
								<RichTextEditor.OrderedList />
								{!hideSubSup && (
									<>
										<RichTextEditor.Subscript />
										<RichTextEditor.Superscript />
									</>
								)}
							</RichTextEditor.ControlsGroup>
							<RichTextEditor.ControlsGroup>
								<RichTextEditor.Link />
								<RichTextEditor.Unlink />
							</RichTextEditor.ControlsGroup>
							{!hideAlignment && (
								<RichTextEditor.ControlsGroup>
									<RichTextEditor.AlignLeft />
									<RichTextEditor.AlignCenter />
									<RichTextEditor.AlignJustify />
									<RichTextEditor.AlignRight />
								</RichTextEditor.ControlsGroup>
							)}
						</>
					)}
				</>
			)}
			<RichTextEditor.ControlsGroup>
				{!!items.length && (
					<Menu
						position={'bottom-end'}
						withArrow>
						<Menu.Target>
							<RichTextEditor.Control
								aria-label="Insert user values"
								title="Insert user values">
								<IconUserPin
									stroke={1.5}
									size="1rem"
								/>
							</RichTextEditor.Control>
						</Menu.Target>
						<Menu.Dropdown>
							<ScrollArea.Autosize mah={500}>
								{items.map((item) => (
									<Menu.Item
										key={item.path || item.param || item.label}
										onClick={() => editor?.chain().focus().insertContent(item.param).run()}>
										{item.label}
									</Menu.Item>
								))}
							</ScrollArea.Autosize>
						</Menu.Dropdown>
					</Menu>
				)}
				{items.filter((item) => item.type === FieldTypes.Link).length > 0 && (
					<Popover
						opened={popoverOpened}
						closeOnClickOutside={false}
						withArrow
						shadow={'md'}
						withinPortal
						onOpen={() => {
							setColor('#1b2942');
							setItem(null);
							setLabel('Preview Button');
						}}>
						<Popover.Target>
							<RichTextEditor.Control
								onClick={openPopover}
								aria-label={'Insert Link Button'}
								title={'Insert Link Button'}>
								<IconRectangle
									stroke={1.5}
									size="1rem"
								/>
							</RichTextEditor.Control>
						</Popover.Target>
						<Popover.Dropdown>
							<Stack>
								<Group justify={'space-between'}>
									<Title
										order={6}
										ta={'center'}>
										Insert Link Button
									</Title>
									<ActionIcon
										onClick={closePopover}
										color={'red'}
										size={'sm'}>
										<IconX />
									</ActionIcon>
								</Group>

								<Select
									title={'Select Link'}
									data={items
										.filter((item) => item.type === FieldTypes.Link)
										.map((item) => ({
											label: item.label,
											value: item.param,
											item,
										}))}
									placeholder={'Select Link'}
									// @ts-ignore
									onChange={(_, it) => {
										// @ts-ignore
										setItem(it?.item || null);
										setLabel(it?.label ? `${it?.label || ''}` : 'Preview Button');
									}}
								/>

								<TextInput
									title={'Button Label'}
									placeholder={'Button Label'}
									value={label}
									onChange={(event) => setLabel(event.currentTarget.value)}
								/>
								<ColorPicker
									value={color}
									onChange={setColor}
								/>
								<div
									title={'Preview button with selected color and label'}
									style={{
										backgroundColor: color,
										padding: '8px 16px',
										color: 'white',
										cursor: 'not-allowed',
										textAlign: 'center',
										borderRadius: '6px',
									}}>
									{label}
								</div>
								<Group justify={'end'}>
									<Button
										disabled={!item}
										onClick={() => {
											editor
												?.chain()
												.focus()
												.insertContent(`<div type="buttonComponent" color="${color}" label="${label}" href="${item?.param}">${label}</div>`)
												.run();
											setColor('#1b2942');
											closePopover();
										}}
										type={'button'}
										size={'xs'}>
										Insert Button
									</Button>
								</Group>
							</Stack>
						</Popover.Dropdown>
					</Popover>
				)}
				{essentialStore.currentUser?.isDev && (
					<RichTextEditor.Control
						title={'Edit HTML Code'}
						onClick={open}>
						<IconCode />
					</RichTextEditor.Control>
				)}
			</RichTextEditor.ControlsGroup>
			<Modal
				title={'Edit HTML Code'}
				size={1000}
				classNames={{
					body: 'tw-flex tw-flex-col tw-p-6',
				}}
				opened={opened}
				onClose={close}>
				<Textarea
					rows={20}
					value={html}
					onChange={(event) => {
						setHtml(event.currentTarget.value);
					}}
				/>
				<Button
					mt={'lg'}
					ml={'auto'}
					onClick={() => {
						editor?.chain().focus().setContent(html).run();
						close();
					}}>
					OK
				</Button>
			</Modal>
			{toolbarRight}
		</RichTextEditor.Toolbar>
	);
};
