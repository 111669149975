import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';
import settings from '@/modules/annualReport/settings';
import reports from '@/modules/annualReport/reports';

const route: RouteObject = {
	path: 'annualReport',
	loader: () => ({
		meta: {
			title: 'AnnualReport',
			to: { id: 'annualReportHome' },
			htmlTitlePattern: '#title :: AnnualReport',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/annualReport/Layout')).default }),
	children: [
		{
			path: '',
			index: true,
			id: 'annualReportHome',
			loader: () => ({
				meta: {
					title: 'Home',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/annualReport/pages/Home')).default }),
		},
		{
			shouldRevalidate: () => true,
			path: '',
			loader: ({ params }) => ({
				meta: {
					title: params.abbreviation,
					to: { id: 'annualReportStateHome', params: { abbreviation: params.abbreviation } },
				} as RouteMeta,
			}),
			children: [
				{
					path: ':abbreviation',
					id: 'annualReportStateHome',
					index: true,
					loader: ({ params }) => ({
						meta: {
							title: 'Home',
							htmlTitle: `${params.abbreviation} Home`,
							disabled: true,
						} as RouteMeta,
					}),
					lazy: async () => ({ Component: (await import('@/modules/annualReport/pages/StateHome')).default }),
				},
				{
					path: ':abbreviation/orders',
					id: 'annualReportOrders',
					index: true,
					loader: ({ params }) => ({
						meta: {
							title: 'Orders',
							htmlTitle: `${params.abbreviation} Orders`,
							disabled: true,
						} as RouteMeta,
					}),
					lazy: async () => ({ Component: (await import('@/modules/annualReport/pages/Orders')).default }),
				},
				{
					path: ':abbreviation/mailings',
					id: 'annualReportMailings',
					index: true,
					loader: ({ params }) => ({
						meta: {
							title: 'Mailings',
							disabled: true,
							htmlTitle: `${params.abbreviation} Mailings`,
						} as RouteMeta,
					}),
					lazy: async () => ({ Component: (await import('@/modules/annualReport/pages/Mailings')).default }),
				},
				{
					path: ':abbreviation/entities',
					id: 'annualReportEntities',
					index: true,
					loader: ({ params }) => ({
						meta: {
							title: 'Entities',
							disabled: true,
							htmlTitle: `${params.abbreviation} Entities`,
						} as RouteMeta,
					}),
					lazy: async () => ({ Component: (await import('@/modules/annualReport/pages/Entities')).default }),
				},
				{
					path: ':abbreviation/:entityID',
					id: 'annualReportEntityProfile',
					index: true,
					loader: ({ params }) => ({
						meta: {
							title: 'Profile',
							disabled: true,
							htmlTitle: `${params.abbreviation} Profile`,
						} as RouteMeta,
					}),
					lazy: async () => ({ Component: (await import('@/modules/annualReport/pages/Profile')).default }),
				},
			],
		},
		settings,
		reports,
	],
};
export default route;
