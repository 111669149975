import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';
import settings from '@/modules/laborLaw/settings';
import reports from '@/modules/laborLaw/reports';

const route: RouteObject = {
	path: 'laborLaw',
	loader: () => ({
		meta: {
			title: 'LaborLaw',
			to: { id: 'laborLawHome' },
			htmlTitlePattern: '#title :: LaborLaw',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/laborLaw/Layout')).default }),
	children: [
		{
			path: '',
			index: true,
			id: 'laborLawHome',
			loader: () => ({
				meta: {
					title: 'Home',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/laborLaw/pages/Home')).default }),
		},
		{
			path: '',
			shouldRevalidate: () => true,
			loader: ({ params }) => ({
				meta: {
					title: params.abbreviation,
					to: { id: 'laborLawStateHome', params: { abbreviation: params.abbreviation } },
				} as RouteMeta,
			}),
			children: [
				{
					path: ':abbreviation',
					id: 'laborLawStateHome',
					index: true,
					loader: ({ params }) => ({
						meta: {
							title: 'Home',
							htmlTitle: `${params.abbreviation} Home`,
							disabled: true,
						} as RouteMeta,
					}),
					lazy: async () => ({ Component: (await import('@/modules/laborLaw/pages/StateHome')).default }),
				},
				{
					path: ':abbreviation/orders',
					id: 'laborLawOrders',
					index: true,
					loader: ({ params }) => ({
						meta: {
							title: 'Orders',
							htmlTitle: `${params.abbreviation} Orders`,
							disabled: true,
						} as RouteMeta,
					}),
					lazy: async () => ({ Component: (await import('@/modules/laborLaw/pages/Orders')).default }),
				},
				{
					path: ':abbreviation/mailings',
					id: 'laborLawMailings',
					index: true,
					loader: ({ params }) => ({
						meta: {
							title: 'Mailings',
							disabled: true,
							htmlTitle: `${params.abbreviation} Mailings`,
						} as RouteMeta,
					}),
					lazy: async () => ({ Component: (await import('@/modules/laborLaw/pages/Mailings')).default }),
				},
				{
					path: ':abbreviation/entities',
					id: 'laborLawEntities',
					index: true,
					loader: ({ params }) => ({
						meta: {
							title: 'Entities',
							disabled: true,
							htmlTitle: `${params.abbreviation} Entities`,
						} as RouteMeta,
					}),
					lazy: async () => ({ Component: (await import('@/modules/laborLaw/pages/Entities')).default }),
				},
				{
					path: ':abbreviation/:entityID',
					id: 'laborLawEntityProfile',
					index: true,
					loader: ({ params }) => ({
						meta: {
							title: 'Profile',
							disabled: true,
							htmlTitle: `${params.abbreviation} Profile`,
						} as RouteMeta,
					}),
					lazy: async () => ({ Component: (await import('@/modules/laborLaw/pages/Profile')).default }),
				},
			],
		},
		settings,
		reports,
	],
};
export default route;
