import { generatePath, LoaderFunction, redirect, RouteObject } from 'react-router-dom';
import ErrorOccurred from '@/pages/ErrorOccurred';
import { api } from '@/lib/connectivity';
import yourKits from '@/modules/yourKits';
import { find } from 'lodash';
import scraper from '@/modules/scraper';
import { ReactNode } from 'react';
import { ThemeIcon } from '@mantine/core';
import { IconHome } from '@tabler/icons-react';
import user from '@/modules/user';
import settings from '@/modules/settings';
import dev from '@/modules/dev';
import brm from '@/modules/brm';
import bank from '@/modules/bank';
import task from '@/modules/task';
import attendance from '@/modules/attendance';
import expense from '@/modules/expense';
import records from '@/modules/records';
import laborLaw from '@/modules/laborLaw';
import invoice from '@/modules/invoice';
import job from '@/modules/job';
import essentialDocuments from '@/modules/essentialDocuments';
import annualReport from '@/modules/annualReport';
import fictitious from '@/modules/fictitious';
import propertyDocument from '@/modules/propertyDocument';
import reports from '@/modules/reports';

export type RouteMeta = {
	title?: ReactNode;
	pageTitle?: string;
	htmlTitle?: string;
	htmlTitlePattern?: string;
	to?: { id?: string; params?: Record<string, any>; path?: string };
	needAuthorization?: boolean;
	disabled?: boolean;
	settingsToolbar?: boolean;
	before?: Array<RouteMeta>;
};

// export const makePath = (id: string, params?: Record<string, any>) => generatePath(, params);
export const verifyUser: LoaderFunction = async () => {
	return await api
		.get('/auth/verify')
		.then(({ data }) => {
			return data.body.user || null;
		})
		.catch(() => {})
		.finally(() => {});
};

export const loadEssential: LoaderFunction = async () => {
	return await api
		.get('/essential')
		.then(({ data }) => {
			return data.body;
		})
		.catch(() => {})
		.finally(() => {});
};

export const routes: RouteObject[] = [
	{
		path: '',
		errorElement: <ErrorOccurred />,
		lazy: async () => ({ Component: (await import('@/layout/AppThemeProvider')).default }),
		children: [
			{
				loader: async (p) => {
					const currentUser = await verifyUser(p);
					if (!currentUser) {
						return redirect('/auth');
					}
					const essential = await loadEssential(p);
					return {
						currentUser,
						essential,
						meta: {
							title: (
								<ThemeIcon>
									<IconHome />
								</ThemeIcon>
							),
							to: { path: '/' },
						} as RouteMeta,
					};
				},
				path: '/',
				lazy: async () => ({ Component: (await import('@/layout/AuthenticatedLayout')).default }),
				children: [
					{
						index: true,
						path: '',
						id: 'home',
						lazy: async () => ({ Component: (await import('@/modules/dashboard/Home')).default }),
					},
					{
						index: true,
						path: 'account',
						id: 'account',
						lazy: async () => ({ Component: (await import('@/modules/auth/Account')).default }),
					},
					{
						path: '/*',
						lazy: async () => ({ Component: (await import('@/pages/NotFound')).default }),
					},
					dev,
					settings,
					user,
					yourKits,
					scraper,
					brm,
					bank,
					task,
					attendance,
					expense,
					invoice,
					records,
					laborLaw,
					job,
					essentialDocuments,
					annualReport,
					fictitious,
					propertyDocument,
					reports,
				],
			},
			{
				path: '/auth',
				loader: async (p) => {
					const currentUser = await verifyUser(p);
					if (currentUser) {
						return redirect('/');
					}
					return null;
				},
				lazy: async () => ({ Component: (await import('@/layout/UnauthenticatedLayout')).default }),
				children: [
					{
						id: 'login',
						path: '',
						index: true,
						lazy: async () => ({ Component: (await import('@/modules/auth/Login')).default }),
					},
					{
						path: '*',
						lazy: async () => ({ Component: (await import('@/pages/NotFound')).default }),
					},
				],
			},
		],
	},
];

function normalize(path: string) {
	let sPath;

	function n(s: string) {
		return s.replace(/\/+/g, '/').replace(/\w+\/+\.\./g, '');
	}

	while (sPath !== path) {
		sPath = n(path);
		path = n(sPath);
	}
	return path.replace(/\/$/, '');
}

export const idRoute = (routeID: string, params?: Record<string, any>, searchParams?: Record<string, any>): string => {
	const paths: Array<{ id: string | undefined; path: string }> = [];
	const result = (items: RouteObject[], base: string) => {
		for (const child of items) {
			if (child.children) {
				result(child.children, normalize(base + '/' + child.path));
			} else {
				if (child.index) {
					paths.push({ path: normalize(base + '/' + child.path), id: child.id });
				}
			}
		}
	};
	result(routes, '');
	try {
		const sp = new URLSearchParams(searchParams).toString();
		// @ts-ignore
		return generatePath(find(paths, { id: routeID }).path || '', params) + (sp ? '?' + sp : '');
	} catch (e: any) {
		console.error(`Error in defined IdRoute "${routeID}"`, e);
		return '';
	}
};
